import { i18n } from 'boot/i18n'
import {
  Message,
  QuotesForTitlesQuotesInner,
  SearchMessagesStatistics,
  SearchMessagesStatisticsAuthorAnalytics,
  SearchMessagesStatisticsCounts
} from '@stockpulse/typescript-axios'
import * as StringHelper from 'src/helper/StringHelper'
import { Auxiliary } from 'src/helper/Auxiliary'
import { ReportModel, ReportModelType } from 'src/helper/ReportHelper'
import * as Sentry from '@sentry/vue'
import { HistoryChartData, HistoryQuoteData } from 'boot/axios'
import { AsyncApiData } from 'src/composables/useAsyncApiData'
import { Title } from 'src/types/Title'
import { StandardTriggersInner } from '@stockpulse/typescript-axios/types/standard-triggers-inner'
import { EarningsTitlesInner } from '@stockpulse/typescript-axios/dist/types/earnings-titles-inner'
import { isShortInfoIntlArray, isShortSaleUsArray, ShortSaleInfos, ShortSalesChartData } from 'src/types/ShortSaleInfo'
import { useInventoryStore } from 'stores/inventory-store'

export interface AuthorDistributionData { label: string, value: number, id: number, source:string }

export interface TitleDistributionData { label: string, value: number, id: number, icon?:string }

export interface LanguageDistributionData { label: string, value: number, identifier: string }

export interface TypeDistributionData { label: string, value: number, identifier:string, icon?: string }

export interface SourceDistributionData { label: string, value: number, identifier:string, icon?:string }

export interface CountryDistributionData { label: string, value: number, identifier:string, icon?:string }

export interface StatisticData {
  sourceDistribution: SourceDistributionData[] | undefined,
  typeDistribution: TypeDistributionData[] | undefined,
  authorDistribution: AuthorDistributionData[] | undefined,
  languageDistribution: LanguageDistributionData[] | undefined,
  titlesDistribution: TitleDistributionData[] | undefined,
  countriesDistribution: CountryDistributionData[] | undefined,
  keyEvents: { label: string, value: number }[] | undefined
  authorAnalytics: { label: string, value: number }[] | undefined
}

export interface StatisticCountData {
  authorCount: number,
  sourcesCount: number,
  totalCount: number,
  positiveMessagesCount: number,
  negativeMessagesCount: number,
  neutralMessagesCount: number
}

export interface SearchMessagesStatisticsTop10AuthorsInner {
  'source'?: string;
  'name'?: string;
  'count'?: number;
  author_id: number
}

export type ChartCoordinate = [number, number]
export type MarkLine = [{ coord: ChartCoordinate, name?:string }, { coord: ChartCoordinate, name?:string }]
export type MarkData = [number, number | null]
export type MarkSeries = { chartSeries: MarkData[], markLines: MarkLine[] }

export interface StackItem { id: number, t?: number, name?: string, total?: number | null }

export function extractStatisticData (messagesStatistics: SearchMessagesStatistics,
  excludeTitleId: number|undefined, titles: Title[]): StatisticData {
  const inventoryStore = useInventoryStore()

  const top10 = messagesStatistics?.top10
  const authorAnalytics = messagesStatistics?.author_analytics
    ? getAuthorAnalytics(messagesStatistics?.author_analytics)
    : undefined
  const sourceDistribution = Object.entries(top10?.sources || {}).map((value) => {
    const source = inventoryStore.getSourceByName(value[0])
    return {
      label: source?.source ?? value[0],
      value: value[1],
      identifier: value[0],
      icon: source?.icon ?? 'spi-globe'
    }
  })
  const typeDistribution = Object.entries(top10?.types || {}).map((value) => {
    return {
      label: i18n.global.t('MessageStatistics.type.' + value[0]),
      value: value[1],
      identifier: value[0],
      icon: inventoryStore.getMessageSourceTypeIconByName(value[0])
    }
  })

  const authorDistribution = (top10?.authors as SearchMessagesStatisticsTop10AuthorsInner[] || []).map((value) => {
    const authorSource = value.source || ''
    return {
      label: value.name || '',
      value: value.count || 0,
      id: value.author_id,
      source: authorSource,
      icon: inventoryStore.getSourceIconByName(authorSource, 'spi-user')
    }
  })
  const languageDistribution = Object.entries(top10?.languages || {}).map((value) => {
    const translation = i18n.global.t('MessageStatistics.language.' + value[0])
    let label = translation
    if (translation.startsWith('MessageStatistics.language.')) {
      label = value[0]
      Sentry.captureMessage(`Could not find translation key for language 'MessageStatistics.language.${value[0]}'`)
    }

    return {
      label,
      value: value[1],
      identifier: value[0]
    }
  })
  const titlesDistribution =
    Object.entries(top10?.titles || {})
      .filter(value => excludeTitleId === undefined || parseInt(value[0]) !== excludeTitleId)
      .filter(value => {
        const title = inventoryStore.getTitleById(parseInt(value[0]))
        return title !== undefined && title.type !== 'class'
      })
      .map((value):TitleDistributionData => {
        const title = inventoryStore.getTitleById(parseInt(value[0]))
        return {
          label: title?.n || '',
          value: value[1],
          id: title?.id || 0,
          icon: title?.icon
        }
      })
  const keyEvents = Object.entries(top10?.key_events || {}).map((value) => {
    return { label: inventoryStore.translateKeyEvent(parseInt(value[0])), value: value[1], id: parseInt(value[0]) }
  })

  const countriesDistribution: CountryDistributionData[] = []
  for (const title of titles) {
    if (title.country === undefined) {
      continue
    }

    const countryItem = countriesDistribution.find(item => item.identifier === title.country)
    if (countryItem === undefined) {
      const country = inventoryStore.getCountryByUn(title.country)

      countriesDistribution.push({
        label: country?.name || title.country,
        value: 1,
        icon: country?.icon,
        identifier: title.country
      })
      continue
    }

    countryItem.value += 1
  }

  return {
    sourceDistribution,
    typeDistribution,
    authorDistribution,
    languageDistribution,
    titlesDistribution,
    countriesDistribution,
    keyEvents,
    authorAnalytics
  }
}

function getAuthorAnalyticsIcon (type : string):string {
  switch (type) {
  case 'bots':
    return 'spi-robot'
  case 'influencers':
    return 'spi-user-circle-signal'
  case 'regulars':
    return 'spi-user'
  case 'verified':
    return 'spi-user-circle-check'
  default:
    return 'spi-user'
  }
}

function getAuthorAnalytics (rawAuthorAnalytics : SearchMessagesStatisticsAuthorAnalytics)
  : Array<{label: string, value:number}> {
  const defaultData: SearchMessagesStatisticsAuthorAnalytics = { bots: 0, influencers: 0, regulars: 0, verified: 0 }
  const analytics: SearchMessagesStatisticsAuthorAnalytics = { ...defaultData, ...rawAuthorAnalytics }
  const total = Object.values(analytics).reduce((a, b) => a + b, 0)
  if (total === 0) {
    return []
  }

  return Object.entries(analytics).map(
    (value: [string, number]) => {
      return {
        label: StringHelper.capitalizeFirstLetter(value[0]),
        value: Math.round(value[1] * 100 / total * 10) / 10 || 0,
        icon: getAuthorAnalyticsIcon(value[0])
      }
    }
  )
}

export function extractStatisticCountData (statisticsCounts: SearchMessagesStatisticsCounts): StatisticCountData {
  const authorCount = statisticsCounts?.authors || 0
  const sourcesCount = statisticsCounts?.sources || 0
  const totalCount = statisticsCounts?.messages || 0
  const positiveMessagesCount = statisticsCounts?.pos || 0
  const negativeMessagesCount = statisticsCounts?.neg || 0
  const neutralMessagesCount = statisticsCounts?.neu || 0

  return {
    authorCount,
    sourcesCount,
    totalCount,
    positiveMessagesCount,
    negativeMessagesCount,
    neutralMessagesCount
  }
}

export interface BuzzSentimentHistory {
    buzzHistory: [number, number][],
    sentimentHistory: [number, number][],
    minDate: number,
    maxDate: number,
    minInterval: number
}

export interface QuoteHistory {
    quoteHistory: [number, number | null][]
    volumeHistory: [number, number | null][]
}

export type EarningDate = { t: number, name?: string }

export interface CompleteChartData {
  chartData: BuzzSentimentHistory,
  quoteChartData: QuoteHistory,
  adHocMarker: Array<Message>,
  earningsBuzzAlerts: Array<StandardTriggersInner>,
  buzzPriceAlerts: Array<StandardTriggersInner>,
  earningsMarker: Array<EarningDate>
}

export type ExtendedEarningsTitlesInner = EarningsTitlesInner & {label?: string}

export function prepareChartData (
  buzzSentimentInputHistory: HistoryChartData,
  quoteInputHistory: HistoryQuoteData,
  adHocMessages?: Array<Message>,
  alerts?: Array<StandardTriggersInner>,
  earnings?: Array<ExtendedEarningsTitlesInner>
): CompleteChartData {
  const buzzSentimentHistory = prepareBuzzSentimentHistory(buzzSentimentInputHistory)
  const quoteHistory = prepareQuoteHistory(quoteInputHistory)

  const quoteHistoryTimestamps = quoteHistory.quoteHistory.map(item => item[0])

  if (buzzSentimentInputHistory.reportModel.type === ReportModelType.REALTIME) {
    // Add zeros for every missing timestamp in quoteHistory
    for (const [timestamp] of buzzSentimentHistory.buzzHistory) {
      if (!quoteHistoryTimestamps.includes(timestamp)) {
        quoteHistory.quoteHistory.push([timestamp, null])
        quoteHistory.volumeHistory.push([timestamp, null])
      }
    }
  }

  // Sort series data by timestamp
  quoteHistory.quoteHistory.sort((a, b) => a[0] - b[0])
  quoteHistory.volumeHistory.sort((a, b) => a[0] - b[0])

  const returnObj: CompleteChartData = {
    chartData: buzzSentimentHistory,
    quoteChartData: quoteHistory,
    adHocMarker: [],
    buzzPriceAlerts: [],
    earningsMarker: [],
    earningsBuzzAlerts: []
  }

  if (adHocMessages) {
    const adHocHistory = prepareAdHocHistory(adHocMessages)
    adHocHistory.sort((a, b) => a.t - b.t)
    returnObj.adHocMarker = adHocHistory
  }

  if (alerts) {
    for (const alert of alerts) {
      alert.t = alert.t ? alert.t * 1000 : undefined
      switch (alert.s_id) {
      case 137572:
        returnObj.buzzPriceAlerts.push(alert)
        break
      case 137620:
        returnObj.earningsBuzzAlerts.push(alert)
        break
      default:
        break
      }
    }
  }

  if (earnings) {
    for (const earning of earnings) {
      if (!earning.release_date) {
        continue
      }

      returnObj.earningsMarker.push({ t: new Date(earning.release_date).getTime(), name: earning.label })
    }
  }

  return returnObj
}

export function prepareMessageHistoryChartData (messageStatistics : AsyncApiData<SearchMessagesStatistics>)
  : AsyncApiData<{ history?: Array<StackItem> }> {
  if (messageStatistics.loading) {
    return {
      ...messageStatistics,
      value: undefined
    }
  }
  const history = messageStatistics.value?.history
  if (!history) {
    return {
      ...messageStatistics,
      value: undefined
    }
  }

  const positiveMessages: Array<StackItem> = history.map(item => {
    return { id: 1, name: 'Positive', total: item.pos, t: item.t }
  })

  const neutralMessages: Array<StackItem> = history.map(item => {
    return { id: 2, name: 'Neutral', total: item.neu, t: item.t }
  })

  const negativeMessages: Array<StackItem> = history.map(item => {
    return { id: 3, name: 'Negative', total: item.neg, t: item.t }
  })

  return {
    ...messageStatistics,
    value: { history: positiveMessages.concat(...neutralMessages, ...negativeMessages) }
  }
}

function prepareBuzzSentimentHistory (
  values: { history: Array<{ t:number, Buzz:number, Sentiment:number }>, reportModel: ReportModel }
): BuzzSentimentHistory {
  const buzzValues : [number, number][] = []
  const sentimentValues : [number, number][] = []

  const minDate: number = Math.min(...values.history.map(item => item.t))
  const maxDate: number = Math.max(...values.history.map(item => item.t))

  switch (values.reportModel.type) {
  case ReportModelType.REALTIME:
    values.history.forEach(entry => {
      buzzValues.unshift([get10MinTimestamp(entry.t), entry.Buzz * 100])
      sentimentValues.unshift([get10MinTimestamp(entry.t), Auxiliary.calculateSentimentScore(entry.Sentiment)])
    })

    return {
      buzzHistory: buzzValues,
      sentimentHistory: sentimentValues,
      minDate: new Date(get10MinTimestamp(minDate)).getTime(),
      maxDate: new Date(get10MinTimestamp(maxDate)).getTime(),
      minInterval: 10 * 60 * 1000
    }
  case ReportModelType.DAILY:
    values.history.forEach(entry => {
      buzzValues.unshift([getTodaysTimestamp(entry.t), entry.Buzz * 100])
      sentimentValues.unshift([getTodaysTimestamp(entry.t), Auxiliary.calculateSentimentScore(entry.Sentiment)])
    })

    return {
      buzzHistory: buzzValues,
      sentimentHistory: sentimentValues,
      minDate: new Date(getTodaysTimestamp(minDate)).getTime(),
      maxDate: new Date(getTodaysTimestamp(maxDate)).getTime(),
      minInterval: 24 * 3600 * 1000
    }
  case ReportModelType.HOURLY:
    values.history.forEach(entry => {
      buzzValues.unshift([getHourlyTimestamp(entry.t), entry.Buzz * 100])
      sentimentValues.unshift([getHourlyTimestamp(entry.t), Auxiliary.calculateSentimentScore(entry.Sentiment)])
    })

    return {
      buzzHistory: buzzValues,
      sentimentHistory: sentimentValues,
      minDate: new Date(getHourlyTimestamp(minDate)).getTime(),
      maxDate: new Date(getHourlyTimestamp(maxDate)).getTime(),
      minInterval: 3600 * 1000
    }
  default:
    throw new Error(`Unexpected report model type: ${values.reportModel.type}`)
  }
}

function prepareQuoteHistory (values: { history: Array<QuotesForTitlesQuotesInner>, reportModel: ReportModel })
  : QuoteHistory {
  const quoteValues : [number, number][] = []
  const volumeValues : [number, number][] = []

  switch (values.reportModel.type) {
  case ReportModelType.REALTIME:
    values.history.forEach(entry => {
      if (!entry.t || !entry.o) {
        return
      }

      const normalizedTimestamp = get10MinTimestamp(entry.t)
      // Do not include more than one value per normalizedTimestamp
      if (entry.o && !quoteValues.map(item => item[0]).includes(normalizedTimestamp)) {
        quoteValues.unshift([normalizedTimestamp, entry.o])
        volumeValues.unshift([normalizedTimestamp, entry.v || 0])
      }
    })

    return {
      quoteHistory: quoteValues,
      volumeHistory: volumeValues
    }
  case ReportModelType.DAILY:
    values.history.forEach(entry => {
      if (!entry.t || !entry.o) {
        return
      }

      const normalizedTimestamp = getTodaysTimestamp(entry.t)
      // Do not include more than one value per normalizedTimestamp
      if (entry.o && !quoteValues.map(item => item[0]).includes(normalizedTimestamp)) {
        quoteValues.unshift([normalizedTimestamp, entry.o])
        volumeValues.unshift([normalizedTimestamp, entry.v || 0])
      }
    })

    return {
      quoteHistory: quoteValues,
      volumeHistory: volumeValues
    }
  case ReportModelType.HOURLY:
    values.history.forEach(entry => {
      if (!entry.t || !entry.o) {
        return
      }

      const normalizedTimestamp = getHourlyTimestamp(entry.t)
      // Do not include more than one value per normalizedTimestamp
      if (entry.o && !quoteValues.map(item => item[0]).includes(normalizedTimestamp)) {
        quoteValues.unshift([normalizedTimestamp, entry.o])
        volumeValues.unshift([normalizedTimestamp, entry.v || 0])
      }
    })

    return {
      quoteHistory: quoteValues,
      volumeHistory: volumeValues
    }
  default:
    throw new Error(`Unexpected report model type: ${values.reportModel.type}`)
  }
}

function prepareAdHocHistory (messages: Array<Message>): Array<Message> {
  const adHocHistory: Array<Message> = []
  for (const message of messages) {
    adHocHistory.push({ ...message, t: message.t * 1000 })
  }
  return adHocHistory
}

export function getTodaysTimestamp (timestamp: number) : number {
  return (new Date(timestamp * 1000)).setHours(0, 0, 0, 0)
}

export function get10MinTimestamp (timestamp: number) : number {
  return (new Date(timestamp * 1000)).setSeconds(0, 0)
}

export function getHourlyTimestamp (timestamp: number) : number {
  return (new Date(timestamp * 1000)).setMinutes(0, 0, 0)
}

export function prepareMarkerSeries (marker: { t?: number, name?: string }[] | undefined): MarkSeries {
  const chartSeries: MarkData[] = []
  const markLines: MarkLine[] = []

  if (marker !== undefined) {
    marker.forEach(alert => {
      if (!alert.t) {
        return
      }

      // Set only null value to use `connectNulls: false` to hide data line
      chartSeries.push([alert.t, null])

      markLines.push([{ coord: [alert.t, 0], name: alert.name }, { coord: [alert.t, 1], name: alert.name }])
    })
  }

  return { chartSeries, markLines }
}

export function prepareShortSalesChartData (data: ShortSaleInfos): ShortSalesChartData | undefined {
  if (!data || data.length === 0) {
    return undefined
  }

  if (isShortSaleUsArray(data)) {
    const datasets: Map<string, [number, number][]> = new Map()
    for (const shortValue of data) {
      if (!datasets.has(shortValue.market)) {
        datasets.set(shortValue.market, [])
      }

      datasets.get(shortValue.market)?.push([shortValue.date * 1000, shortValue.shortVolume])
    }

    return {
      series: Array.from(datasets.entries()).map(entry => {
        return { name: entry[0], data: entry[1] }
      }),
      inPercentage: false
    }
  }

  if (isShortInfoIntlArray(data)) {
    const datasets: Map<string, [number, number][]> = new Map()
    for (const shortValue of data) {
      if (!shortValue.date_short || !shortValue.shares_percent_short) {
        continue
      }

      const shortDate = Date.parse(shortValue.date_short)
      if (shortDate + 365 * 24 * 60 * 60 * 1000 < Date.now()) {
        continue
      }

      // Short sale data for Canada does not contain a market nor a company name
      const companyShortSelling = shortValue.company_short_selling ?? ''

      if (!datasets.has(companyShortSelling)) {
        datasets.set(companyShortSelling, [])
      }

      datasets.get(companyShortSelling)?.push([shortDate, shortValue.shares_percent_short])
    }

    return {
      series: Array.from(datasets.entries()).map(entry => {
        return { name: entry[0], data: entry[1] }
      }).filter(dataset => dataset.data.length > 1),
      inPercentage: true
    }
  }

  throw new Error('Unknown short info data type!')
}
